import DropDownMenu_SolidColorMenuButtonSkinComponent from '@wix/thunderbolt-elements/src/components/DropDownMenu/viewer/skinComps/SolidColorMenuButtonSkin/SolidColorMenuButtonSkin.skin';
import DropDownMenu_SolidColorMenuButtonSkinController from '@wix/thunderbolt-elements/src/components/DropDownMenu/viewer/DropDownMenu.controller';


const DropDownMenu_SolidColorMenuButtonSkin = {
  component: DropDownMenu_SolidColorMenuButtonSkinComponent,
  controller: DropDownMenu_SolidColorMenuButtonSkinController
};


export const components = {
  ['DropDownMenu_SolidColorMenuButtonSkin']: DropDownMenu_SolidColorMenuButtonSkin
};

